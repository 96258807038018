<template>
  <div>
    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-card-title style="font-size: 20px; height: 70px">
          <p>
            <v-icon class="mb-1 mr-2" style="font-size: 28px; color: #424242"
              >mdi-square-edit-outline</v-icon
            >{{ $t("editdepartmentone") }}
          </p>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="$emit('close')"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
              margin-bottom: 16px;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="editNodeptData">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="9">
                <label for="deparmentone" class="font-style"
                  >{{ $t("deparmentone") }}
                  <span style="color: red">*</span></label
                >
                <v-text-field
                  :rules="nameRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="departmentName"
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="2"></v-col>
              <v-col cols="9">
                <label for="memberlistsm" class="font-style"
                  >{{ $t("memberlistsm") }}
                  <span style="color: red">*</span></label
                >
                <v-combobox
                  readonly
                  multiple
                  hide-selected
                  :rules="memberRules"
                  append-icon=""
                  outlined
                  dense
                  hide-details="auto"
                  :items="members"
                  item-text="fullName"
                  item-value="id"
                  v-model="list"
                  class="mt-2"
                ></v-combobox>
              </v-col>
              <v-col cols="2">
                <v-btn
                  width="120"
                  color="secondary"
                  block
                  class="mt-7 font-style text-capitalize"
                  @click="addMemberList()"
                  >{{ $t("add") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            width="100"
            class="text-capitalize btnfont-style"
            @click="
              $store.commit('clearSelected'),
                $emit('close'),
                $refs.form.resetValidation()
            "
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            color="secondary"
            width="100"
            class="text-capitalize btnfont-style"
            @click="submit()"
            >{{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    startingActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      "editNodeptData",
      "selectedUserList",
      "startingNoUserDepartmentActive",
      "levelList",
      "getEditUsers",
      "saveEditDepartmentData",
    ]),
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    groupId: {
      get() {
        return this.editNodeptData.uGroupID;
      },
      set(e) {
        this.editNoUserDepartment.groupId = e;
      },
    },
    departmentName: {
      get() {
        return this.editNodeptData.departmentName;
      },
      set(e) {
        this.editNoUserDepartment.departmentName = e;
      },
    },
    active() {
      return this.startingNoUserDepartmentActive;
    },
    selectedMember() {
      return this.selectedUserList;
    },
    idRules() {
      return [(v) => !!v || "ID is required"];
    },
    nameRules() {
      return [(v) => !!v || "Name is required"];
    },
    memberRules() {
      return [(v) => !!v || "Member list is required"];
    },
  },
  data: () => ({
    valid: true,
    editNoUserDepartment: {
      groupId: "",
      departmentName: "",
      role: "",
    },
    members: [],
    list: [],
    level: [],
  }),
  watch: {
    active(val) {
      if (val) {
        this.getActiveMemberList();
      }
    },
    selectedMember(val) {
      if (val) {
        this.getSelectedMemberList();
      }
    },
  },
  methods: {
    getActiveMemberList() {
      this.list = [];
      for (const element of this.editNodeptData.departmentUsers) {
        this.list.push(element.fullname);
      }
    },

    getSelectedMemberList() {
      this.list = [];
      for (const element of this.selectedUserList) {
        this.list.push(element.fullName);
      }
    },

    addMemberList() {
      this.$emit("close");
      let array = [];
      if (this.selectedUserList.length > 0) {
        for (const element of this.selectedUserList) {
          let found = this.editNodeptData.departmentUsers.find(
            (c) => c.userID == element.id
          );
          if (found != undefined) {
            array.push({
              id: found.userID,
              departmentId: found.departmentID,
              fullName: found.fullname,
            });
          } else {
            array.push({
              id: element.id,
              departmentId: element.departmentId,
              fullName: element.fullName,
            });
          }
        }
      } else {
        for (const element of this.editNodeptData.departmentUsers) {
          array.push({
            id: element.userID,
            departmentId: element.departmentID,
            fullName: element.fullname,
          });
        }
      }
      this.$store.commit("saveSelectedUser", array);
      this.$store.commit("closeEditDepartmentDialog");
      this.$store.commit("editNoUserDepartment", true);
      this.$store.commit("EditDepartmentTable", true);
      this.$store.commit("saveSearchandBtnFlag", false);
      this.$store.commit("EditDepartmentOneTable", true);
    },

    async submit() {
      if (this.$refs.form.validate()) {
        let userIds = [];
        if (this.selectedUserList.length > 0) {
          userIds = this.selectedUserList.map((user) => user.id);
        } else {
          userIds = this.editNodeptData.departmentUsers.map(
            (user) => user.userID
          );
        }

        const userdata = JSON.parse(
          localStorage.getItem("vivek_authenticated_user_data")
        );
        const memberid = userdata.memberId;
        let data = {
          companyId: localStorage.getItem("companyID"),
          departmentId: this.editNodeptData.departmentId,
          createOrUpdateBy: memberid,
          departmentName: this.editNoUserDepartment.departmentName
            ? this.editNoUserDepartment.departmentName
            : this.editNodeptData.departmentName,
          userId: userIds,
        };
        await this.$axios.post(
          `${this.web_url}Department/EditUserDepartment`,
          data
        );
        this.$emit("confirm");
        this.getDepartmentList();
      }
    },
    async getDepartmentList() {
      const res = await this.$axios.get(
        `${this.web_url}Department/GetDepartmentList?id=` +
          localStorage.getItem("companyID")
      );
      this.$store.commit("savedeparmentList", res.data.data);
    },
  },
};
</script>
<style scoped>
::v-deep .v-dialog {
  border-radius: 35px !important;
}
.font-style {
  font-size: 16px;
  color: #4d4f5c;
}
.btnfont-style {
  font-size: 16px;
}
::v-deep .v-label {
  font-size: 16px !important;
  color: #4d4f5c !important;
}
::v-deep .v-input__slot {
  font-size: 16px !important;
  border-radius: 10px !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important ;
}
</style>

<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
