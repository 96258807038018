<template>
  <div>
    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-card-title style="font-size: 20px; height: 70px">
          <p>
            <v-icon class="mb-1 mr-2" style="font-size: 28px; color: #424242"
              >mdi-account-multiple-plus-outline</v-icon
            >{{ $t("editdepartment") }}
          </p>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="$emit('close')"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
              margin-bottom: 16px;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="editDepartmentData">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="9">
                <label for="departmentid" class="font-style"
                  >{{ $t("departmentid")
                  }}<span style="color: red">*</span></label
                >
                <v-text-field
                  :rules="idRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="groupId"
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="2"></v-col>
              <v-col cols="9">
                <label for="departmentnamesm" class="font-style"
                  >{{ $t("departmentnamesm") }}
                  <span style="color: red">*</span></label
                >
                <v-text-field
                  :rules="nameRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="groupName"
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="2"></v-col>
              <v-col cols="9">
                <label for="memberlistsm" class="font-style"
                  >{{ $t("memberlistsm") }}
                  </label>
                <v-combobox
                  readonly
                  multiple
                  hide-selected
                  append-icon=""
                  outlined
                  dense
                  hide-details="auto"
                  :items="members"
                  item-text="employeeName"
                  item-value="employeeID"
                  v-model="list"
                  class="mt-2"
                ></v-combobox>
              </v-col>
              <v-col cols="2">
                <v-btn
                  width="120"
                  color="secondary"
                  block
                  class="mt-7 font-style text-capitalize"
                  @click="addMemberList()"
                  >{{ $t("add") }}</v-btn
                >
              </v-col>
              <v-col cols="9">
                <label for="role" class="font-style"
                  >{{ $t("role") }}<span style="color: red">*</span></label
                >
                <v-select
                  v-model="editDepartmentData.groupRole"
                  :items="levelList"
                  flat
                  hide-details
                  item-text="roleName"
                  item-value="userRoleID"
                  hide-no-data
                  :rules="levelRules"
                  outlined
                  dense
                  clearable
                  multiple
                  class="mt-2 roleselect"
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="getSelectAll"
                      style="border-bottom: 1px solid #00000026"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="
                            editDepartmentData.groupRole
                              ? editDepartmentData.groupRole.length == 0
                                ? '#a6cc39'
                                : ''
                              : ''
                          "
                          >{{ icon }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      v-if="index === 0"
                      style="background: rgba(121, 190, 67, 0.5)"
                    >
                      <span>{{ item.roleName }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption"
                      >(+{{
                        editDepartmentData.groupRole.length - 1
                      }}
                      others)</span
                    >
                  </template>
                </v-select>
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            width="100"
            class="text-capitalize btnfont-style"
            @click="
              $store.commit('clearSelected'),
                $emit('close'),
                $refs.form.resetValidation()
            "
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            color="secondary"
            width="100"
            class="text-capitalize btnfont-style"
            @click="submit()"
            >{{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    startingActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      "editDepartmentData",
      "selectedMemberList",
      "startingDepartmentActive",
      "levelList",
    ]),
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    groupId: {
      get() {
        return this.editDepartmentData.uGroupID;
      },
      set(e) {
        this.editDepartment.groupId = e;
      },
    },
    groupName: {
      get() {
        return this.editDepartmentData.groupName;
      },
      set(e) {
        this.editDepartment.groupName = e;
      },
    },
    // role: {
    //   get() {
    //     return this.editDepartmentData.roleName;
    //   },
    //   set(e) {
    //     this.editDepartment.role = e;
    //   },
    // },
    active() {
      return this.startingDepartmentActive;
    },
    selectedMember() {
      return this.selectedMemberList;
    },
    selectAll() {
      return this.editDepartmentData.groupRole
        ? this.editDepartmentData.groupRole.length === this.levelList.length
        : 0;
    },
    idRules() {
      return [(v) => !!v || "ID is required"];
    },
    nameRules() {
      return [(v) => !!v || "Name is required"];
    },
    memberRules() {
      return [(v) => !!v || "Member list is required"];
    },
    levelRules() {
      return [(v) => !!v || "Level is required"];
    },
    icon() {
      if (this.selectAll) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
  },
  data: () => ({
    valid: true,
    editDepartment: {
      groupId: "",
      groupName: "",
      role: "",
    },
    members: [],
    list: [],
    level: [],
  }),
  watch: {
    active(val) {
      if (val) {
        this.getActiveMemberList();
      }
    },
    selectedMember(val) {
      if (val) {
        this.getSelectedMemberList();
      }
    },
  },
  mounted() {
    this.getLevel();
    this.getUserData();
  },
  methods: {
    getSelectAll() {
      if (this.editDepartmentData.groupRole.length == 0) {
        this.editDepartmentData.groupRole = this.levelList.map(
          ({ userRoleID }) => userRoleID
        );
      } else {
        this.editDepartmentData.groupRole = [];
      }
    },
    async getLevel() {
      const res = await this.$axios.post(
        `${this.web_url}RoleAndPermission/GetRoleForUserAndGroup?companyid=` +
          localStorage.getItem("companyID")
      );
      this.level = res.data.data;
      this.$store.commit("saveroleList", res.data.data);
    },

    async getGroupAll() {
      const res = await this.$axios.get(
        `${this.web_url}Group/GetGroupALL?id=` +
          localStorage.getItem("companyID")
      );
      let groups = [];
      for (const element of res.data.data) {
        groups.push({
          groupID: element["groupID"],
          groupName: element["groupName"],
        });
        delete element.groupID;
        delete element.groupName;
      }
      this.$store.commit("editeparmentList", groups);
    },
    async getUserData() {
      this.members = [];
      const res = await this.$axios.get(
        `${this.web_url}EmployeeEXT/GetEmployeeExtAll?id=` +
          localStorage.getItem("companyID")
      );
      this.members = res.data.data;
    },

    getActiveMemberList() {
      this.list = [];
      for (const element of this.editDepartmentData.groupEmployee) {
        this.list.push(element.employeeName);
      }
    },

    getSelectedMemberList() {
      this.list = [];
      for (const element of this.selectedMemberList) {
        this.list.push(element.employeeName);
      }
    },

    addMemberList() {
      this.$emit("close");

      let array = [];
      if (this.selectedMemberList.length > 0) {
        for (const element of this.selectedMemberList) {
          let found = this.editDepartmentData.groupEmployee.find(
            (c) => c.employeeID == element.userID
          );
          if (found != undefined) {
            array.push({
              userID: found.employeeID,
              groupID: found.groupID,
              employeeName: found.employeeName,
              tEmp_Group_ID: found.tEmp_Group_ID,
            });
          } else {
            array.push({
              userID: element.userID,
              groupID: element.groupID,
              employeeName: element.employeeName,
              tEmp_Group_ID: element.tEmp_Group_ID,
            });
          }
        }
      } else {
        for (const element of this.editDepartmentData.groupEmployee) {
          array.push({
            userID: element.employeeID,
            groupID: element.groupID,
            employeeName: element.employeeName,
            tEmp_Group_ID: element.tEmp_Group_ID,
          });
        }
      }
      this.$store.commit("saveSelectedDepartmentItem", array);
      this.$store.commit("closeEditDepartmentDialog");
      this.$store.commit("editDepartment", true);
      this.$store.commit("DepartmentTable", true);
      this.$store.commit("saveSearchandBtnFlag", false);
    },

    async submit() {
      if (this.$refs.form.validate()) {
        let empGroup = [];
        for (const element of this.selectedMemberList) {
          empGroup.push({
            employeeID: element.userID,
            groupID: 0,
            tEmp_Group_ID: 0,
          });
        }

        let roleGroup = [];
        for (const element of this.editDepartmentData.groupRole) {
          if (element.userRoleID == undefined) {
            roleGroup.push({
              roleID: element,
            });
          } else {
            roleGroup.push({
              roleID: element.userRoleID,
            });
          }
        }

        let data = {
          companyId: localStorage.getItem("companyID"),
          groupID: this.editDepartmentData.groupID,
          uGroupID: this.editDepartment.groupId
            ? this.editDepartment.groupId
            : this.editDepartmentData.groupID.toString(),
          groupName: this.editDepartment.groupName
            ? this.editDepartment.groupName
            : this.editDepartmentData.groupName,
          groupEmployee:
            empGroup.length != 0
              ? empGroup
              : this.editDepartmentData.groupEmployee,
          groupRole: roleGroup,
        };
        await this.$axios.post(`${this.web_url}Group/UpdateGroup`, data);
        this.$emit("confirm");
        this.getGroupAll();
      }
    },
  },
};
</script>
<style scoped>
::v-deep .v-dialog {
  border-radius: 35px !important;
}
.font-style {
  font-size: 16px;
  color: #4d4f5c;
}
.btnfont-style {
  font-size: 16px;
}
::v-deep .v-label {
  font-size: 16px !important;
  color: #4d4f5c !important;
}
::v-deep .v-input__slot {
  font-size: 16px !important;
  border-radius: 10px !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important ;
}
</style>

<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
