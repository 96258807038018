var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"width":"600","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"font-size":"20px","height":"70px"}},[_c('p',[_c('v-icon',{staticClass:"mb-1 mr-2",staticStyle:{"font-size":"28px","color":"#424242"}},[_vm._v("mdi-account-multiple-plus-outline")]),_vm._v(_vm._s(_vm.$t("newdepartment"))+" ")],1),_c('v-spacer'),_c('v-btn',{staticStyle:{"border-radius":"8px !important","border":"1px solid rgba(66, 66, 66, 0.05)","margin-bottom":"16px"},attrs:{"icon":"","color":"#424242"},on:{"click":function($event){_vm.$store.commit('clearSelected'),
              _vm.$emit('close'),
              (_vm.department = []),
              _vm.$refs.form.reset()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"9"}},[_c('label',{staticClass:"font-style",attrs:{"for":"departmentid"}},[_vm._v(_vm._s(_vm.$t("departmentid"))),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('v-text-field',{staticClass:"mt-2",attrs:{"rules":_vm.idRules,"outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.department.id),callback:function ($$v) {_vm.$set(_vm.department, "id", $$v)},expression:"department.id"}})],1),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"9"}},[_c('label',{staticClass:"font-style",attrs:{"for":"departmentnamesm"}},[_vm._v(_vm._s(_vm.$t("departmentnamesm"))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('v-text-field',{staticClass:"mt-2",attrs:{"rules":_vm.nameRules,"outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.department.name),callback:function ($$v) {_vm.$set(_vm.department, "name", $$v)},expression:"department.name"}})],1),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"9"}},[_c('label',{staticClass:"font-style",attrs:{"for":"memberlistsm"}},[_vm._v(_vm._s(_vm.$t("memberlistsm"))+" ")]),_c('v-combobox',{staticClass:"mt-2",attrs:{"append-icon":"","items":_vm.users,"readonly":"","multiple":"","item-text":"fullName","item-value":"empID","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.department.list),callback:function ($$v) {_vm.$set(_vm.department, "list", $$v)},expression:"department.list"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"mt-8 font-style text-capitalize",attrs:{"color":"secondary","width":"120","block":""},on:{"click":function($event){_vm.$emit('close'),
                    _vm.$store.commit('closeAddDepartmentDialog'),
                    _vm.$store.commit('DepartmentTable', true),
                    _vm.$store.commit('saveSearchandBtnFlag', false)}}},[_vm._v(_vm._s(_vm.$t("add")))])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('label',{staticClass:"font-style",attrs:{"for":"role"}},[_vm._v(_vm._s(_vm.$t("role"))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('v-select',{staticClass:"mt-2",attrs:{"items":_vm.levelList,"flat":"","hide-details":"","item-text":"roleName","item-value":"userRoleID","hide-no-data":"","rules":_vm.levelRules,"outlined":"","dense":"","clearable":"","multiple":"","placeholder":"-- Please select --"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{staticStyle:{"border-bottom":"1px solid #00000026"},attrs:{"ripple":""},on:{"click":_vm.getSelectAll}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.department.level
                            ? _vm.department.level.length > 0
                              ? '#a6cc39'
                              : ''
                            : ''}},[_vm._v(_vm._s(_vm.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("All")])],1)],1)]},proxy:true},{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{staticStyle:{"background":"rgba(121, 190, 67, 0.5)"}},[_c('span',[_vm._v(_vm._s(item.roleName))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("(+"+_vm._s(_vm.department.level.length - 1)+" others)")]):_vm._e()]}}]),model:{value:(_vm.department.level),callback:function ($$v) {_vm.$set(_vm.department, "level", $$v)},expression:"department.level"}})],1),_c('v-col',{attrs:{"cols":"2"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"mt-3 pb-4"},[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize btnfont-style",attrs:{"outlined":"","text":"","width":"100"},on:{"click":function($event){_vm.$store.commit('clearSelected'),
              _vm.$emit('close'),
              (_vm.department = []),
              _vm.$refs.form.reset()}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-btn',{staticClass:"text-capitalize btnfont-style",attrs:{"color":"secondary","width":"100"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.$t("submit")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }