<template>
  <div>
    <v-dialog
      class="permission_dialog"
      v-model="dialog"
      width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title style="height: 70px; border-bottom: 1px solid #c0c0c05c">
          <v-col>
            <span style="font-size: 20px; color: #424242">{{
              $t("permissions")
            }}</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              $emit('close'),
                $store.commit(
                  editLevel ? 'openLevelEditDialog' : 'openLevelAddDialog'
                )
            "
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
              margin-bottom: 16px;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            style="overflow-y: scroll; overflow-x: hidden; height: 700px"
          >
            <v-row>
              <v-col cols="12" style="padding-left: 53px">
                <span>{{ $t("usage") }}</span>
              </v-col>
              <v-col cols="12" style="padding-left: 67px">
                <v-btn
                  @click="
                    (selected_new.mobileOrWebUsage_Permission = 2),
                      (editing = true)
                  "
                  style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                  :style="[
                    selected_new.mobileOrWebUsage_Permission == 2
                      ? { 'background-color': '#A6CC39', color: '#ffffff' }
                      : {
                          'background-color': '#ffffff',
                          color: '#A6CC39',
                          border: '1px solid #A6CC39',
                        },
                  ]"
                  class="text-capitalize mr-3"
                  >{{ $t("mobileonly") }}</v-btn
                >
                <v-btn
                  @click="
                    (selected_new.mobileOrWebUsage_Permission = 1),
                      (editing = false)
                  "
                  style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                  :style="[
                    selected_new.mobileOrWebUsage_Permission == 1
                      ? { 'background-color': '#A6CC39', color: '#ffffff' }
                      : {
                          'background-color': '#ffffff',
                          color: '#A6CC39',
                          border: '1px solid #A6CC39',
                        },
                  ]"
                  class="text-capitalize ml-3"
                  >{{ $t("web&mobile") }}</v-btn
                >
              </v-col>
            </v-row>
            <v-divider
              style="border: 1px solid #a6cc39"
              class="mt-5 mb-6 mr-3"
            ></v-divider>
            <v-row class="pl-5 accessright">
              <p>
                {{ $t("accessrights") }}
              </p>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12" class="pl-6">
                    <v-checkbox
                      :disabled="editing"
                      v-model="selected_new.library_Checked"
                      hide-details
                      color="#a6cc39"
                      class="permission_checkbox"
                      :label="$t('library')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" style="padding-left: 70px">
                    <v-btn
                      :disabled="!selected_new.library_Checked || editing"
                      @click="selected_new.library_Permission = 0"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.library_Checked == true &&
                              selected_new.library_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.library_Checked == true &&
                                  selected_new.library_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.library_Checked == false
                          ? [
                              !selected_new.library_Checked == true &&
                              selected_new.library_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.library_Checked == true &&
                              selected_new.library_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize mr-6"
                      >{{ $t("View") }}</v-btn
                    >
                    <v-btn
                      :disabled="!selected_new.library_Checked || editing"
                      @click="selected_new.library_Permission = 1"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.library_Checked == true &&
                              selected_new.library_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.library_Checked == true &&
                                  selected_new.library_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.library_Checked == false &&
                                  selected_new.lessonLearn_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.library_Checked == false
                          ? [
                              !selected_new.library_Checked == true &&
                              selected_new.library_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.library_Checked == false &&
                                  selected_new.lessonLearn_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.library_Checked == true &&
                              selected_new.library_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.library_Checked == false &&
                                  selected_new.library_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize"
                      >{{
                        $t("add") + "," + $t("edit") + "," + $t("delete")
                      }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="12" class="pl-6">
                    <v-checkbox
                      :disabled="editing"
                      v-model="selected_new.lessonlearn_Checked"
                      hide-details
                      color="#a6cc39"
                      class="permission_checkbox"
                      :label="$t('lessonlearn')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" style="padding-left: 70px">
                    <v-btn
                      :disabled="!selected_new.lessonlearn_Checked || editing"
                      @click="selected_new.lessonLearn_Permission = 0"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.lessonlearn_Checked == true &&
                              selected_new.lessonLearn_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.lessonlearn_Checked == true &&
                                  selected_new.lessonLearn_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.lessonlearn_Checked == false
                          ? [
                              !selected_new.lessonlearn_Checked == true &&
                              selected_new.lessonLearn_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.lessonlearn_Checked == true &&
                              selected_new.lessonLearn_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize mr-6"
                      >{{ $t("View") }}</v-btn
                    >
                    <v-btn
                      :disabled="!selected_new.lessonlearn_Checked || editing"
                      @click="selected_new.lessonLearn_Permission = 1"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.lessonlearn_Checked == true &&
                              selected_new.lessonLearn_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.lessonlearn_Checked == true &&
                                  selected_new.lessonLearn_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.lessonlearn_Checked == false
                          ? [
                              !selected_new.lessonlearn_Checked == true &&
                              selected_new.lessonLearn_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.lessonlearn_Checked == true &&
                              selected_new.lessonLearn_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.lessonlearn_Checked == false &&
                                  selected_new.lessonLearn_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize"
                      >{{
                        $t("add") + "," + $t("edit") + "," + $t("delete")
                      }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12" class="pl-6">
                    <v-checkbox
                      :disabled="editing"
                      v-model="selected_new.course_Checked"
                      hide-details
                      color="#a6cc39"
                      class="permission_checkbox"
                      :label="$t('course')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" style="padding-left: 70px">
                    <v-btn
                      :disabled="!selected_new.course_Checked || editing"
                      @click="selected_new.course_Permission = 0"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.course_Checked == true &&
                              selected_new.course_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.course_Checked == true &&
                                  selected_new.course_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.course_Checked == false
                          ? [
                              !selected_new.course_Checked == true &&
                              selected_new.course_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.course_Checked == true &&
                              selected_new.course_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize mr-6"
                      >{{ $t("View") }}</v-btn
                    >
                    <v-btn
                      :disabled="!selected_new.course_Checked || editing"
                      @click="selected_new.course_Permission = 1"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.course_Checked == true &&
                              selected_new.course_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.course_Checked == true &&
                                  selected_new.course_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.course_Checked == false
                          ? [
                              !selected_new.course_Checked == true &&
                              selected_new.course_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.course_Checked == true &&
                              selected_new.course_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.course_Checked == false &&
                                  selected_new.course_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize"
                      >{{
                        $t("add") + "," + $t("edit") + "," + $t("delete")
                      }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="12" class="pl-6">
                    <v-checkbox
                      :disabled="editing"
                      v-model="selected_new.quiz_Checked"
                      hide-details
                      color="#a6cc39"
                      class="permission_checkbox"
                      :label="$t('quiz')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" style="padding-left: 70px">
                    <v-btn
                      :disabled="!selected_new.quiz_Checked || editing"
                      @click="selected_new.quiz_Permission = 0"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.quiz_Checked == true &&
                              selected_new.quiz_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.quiz_Checked == true &&
                                  selected_new.quiz_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.quiz_Checked == false
                          ? [
                              !selected_new.quiz_Checked == true &&
                              selected_new.quiz_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.quiz_Checked == true &&
                              selected_new.quiz_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize mr-6"
                      >{{ $t("View") }}</v-btn
                    >
                    <v-btn
                      :disabled="!selected_new.quiz_Checked || editing"
                      @click="selected_new.quiz_Permission = 1"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.quiz_Checked == true &&
                              selected_new.quiz_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.quiz_Checked == true &&
                                  selected_new.quiz_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.quiz_Checked == false
                          ? [
                              !selected_new.quiz_Checked == true &&
                              selected_new.quiz_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.quiz_Checked == true &&
                              selected_new.quiz_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.quiz_Checked == false &&
                                  selected_new.quiz_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize"
                      >{{
                        $t("add") + "," + $t("edit") + "," + $t("delete")
                      }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12" class="pl-6">
                    <v-checkbox
                      :disabled="editing"
                      v-model="selected_new.community_Checked"
                      hide-details
                      color="#a6cc39"
                      class="permission_checkbox"
                      :label="$t('community')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" style="padding-left: 70px">
                    <v-btn
                      :disabled="!selected_new.community_Checked || editing"
                      @click="selected_new.community_Permission = 0"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.community_Checked == true &&
                              selected_new.community_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.community_Checked == true &&
                                  selected_new.community_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.community_Checked == false
                          ? [
                              !selected_new.community_Checked == true &&
                              selected_new.community_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.community_Checked == true &&
                              selected_new.community_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize mr-6"
                      >{{ $t("View") }}</v-btn
                    >
                    <v-btn
                      :disabled="!selected_new.community_Checked || editing"
                      @click="selected_new.community_Permission = 1"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.community_Checked == true &&
                              selected_new.community_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.community_Checked == true &&
                                  selected_new.community_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.community_Checked == false
                          ? [
                              !selected_new.community_Checked == true &&
                              selected_new.community_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.community_Checked == true &&
                              selected_new.community_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.community_Checked == false &&
                                  selected_new.community_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize"
                      >{{
                        $t("add") + "," + $t("edit") + "," + $t("delete")
                      }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="12" class="pl-6">
                    <v-checkbox
                      :disabled="editing"
                      v-model="selected_new.event_Checked"
                      hide-details
                      color="#a6cc39"
                      class="permission_checkbox"
                      :label="$t('event')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" style="padding-left: 70px">
                    <v-btn
                      :disabled="!selected_new.event_Checked || editing"
                      @click="selected_new.event_Permission = 0"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.event_Checked == true &&
                              selected_new.event_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.event_Checked == true &&
                                  selected_new.event_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.event_Checked == false
                          ? [
                              !selected_new.event_Checked == true &&
                              selected_new.event_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.event_Checked == true &&
                              selected_new.event_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize mr-6"
                      >{{ $t("View") }}</v-btn
                    >
                    <v-btn
                      :disabled="!selected_new.event_Checked || editing"
                      @click="selected_new.event_Permission = 1"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.event_Checked == true &&
                              selected_new.event_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.event_Checked == true &&
                                  selected_new.event_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.event_Checked == false
                          ? [
                              !selected_new.event_Checked == true &&
                              selected_new.event_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.event_Checked == true &&
                              selected_new.event_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.event_Checked == false &&
                                  selected_new.event_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize"
                      >{{
                        $t("add") + "," + $t("edit") + "," + $t("delete")
                      }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12" class="pl-6">
                    <v-checkbox
                      :disabled="editing"
                      v-model="selected_new.banner_Checked"
                      hide-details
                      color="#a6cc39"
                      class="permission_checkbox"
                      :label="$t('banner')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" style="padding-left: 70px">
                    <v-btn
                      :disabled="!selected_new.banner_Checked || editing"
                      @click="selected_new.banner_Permission = 0"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.banner_Checked == true &&
                              selected_new.banner_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.banner_Checked == true &&
                                  selected_new.banner_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.banner_Checked == false
                          ? [
                              !selected_new.banner_Checked == true &&
                              selected_new.banner_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.banner_Checked == true &&
                              selected_new.banner_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize mr-6"
                      >{{ $t("View") }}</v-btn
                    >
                    <v-btn
                      :disabled="!selected_new.banner_Checked || editing"
                      @click="selected_new.banner_Permission = 1"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.banner_Checked == true &&
                              selected_new.banner_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.banner_Checked == true &&
                                  selected_new.banner_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.banner_Checked == false
                          ? [
                              !selected_new.banner_Checked == true &&
                              selected_new.banner_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.banner_Checked == true &&
                              selected_new.banner_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.banner_Checked == false &&
                                  selected_new.banner_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize"
                      >{{
                        $t("add") + "," + $t("edit") + "," + $t("delete")
                      }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="12" class="pl-6">
                    <v-checkbox
                      :disabled="editing"
                      v-model="selected_new.feedbackbox_Checked"
                      hide-details
                      color="#a6cc39"
                      class="permission_checkbox"
                      :label="$t('feedbackbox')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" style="padding-left: 70px">
                    <v-btn
                      :disabled="!selected_new.feedbackbox_Checked || editing"
                      @click="selected_new.feedbackbox_Permission = 0"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.feedbackbox_Checked == true &&
                              selected_new.feedbackbox_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.feedbackbox_Checked == true &&
                                  selected_new.feedbackbox_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.feedbackbox_Checked == false
                          ? [
                              !selected_new.feedbackbox_Checked == true &&
                              selected_new.feedbackbox_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.feedbackbox_Checked == true &&
                              selected_new.feedbackbox_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize mr-6"
                      >{{ $t("View") }}</v-btn
                    >
                    <v-btn
                      :disabled="!selected_new.feedbackbox_Checked || editing"
                      @click="selected_new.feedbackbox_Permission = 1"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.feedbackbox_Checked == true &&
                              selected_new.feedbackbox_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.feedbackbox_Checked == true &&
                                  selected_new.feedbackbox_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.feedbackbox_Checked == false
                          ? [
                              !selected_new.feedbackbox_Checked == true &&
                              selected_new.feedbackbox_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.feedbackbox_Checked == true &&
                              selected_new.feedbackbox_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.feedbackbox_Checked == false &&
                                  selected_new.feedbackbox_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize"
                      >{{
                        $t("add") + "," + $t("edit") + "," + $t("delete")
                      }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12" class="pl-6">
                    <v-checkbox
                      :disabled="editing"
                      v-model="selected_new.usermanage_Checked"
                      hide-details
                      color="#a6cc39"
                      class="permission_checkbox"
                      :label="$t('usermanagement')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" style="padding-left: 70px">
                    <v-btn
                      :disabled="!selected_new.usermanage_Checked || editing"
                      @click="selected_new.usermanage_Permission = 0"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.usermanage_Checked == true &&
                              selected_new.usermanage_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.usermanage_Checked == true &&
                                  selected_new.usermanage_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.usermanage_Checked == false
                          ? [
                              !selected_new.usermanage_Checked == true &&
                              selected_new.usermanage_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.usermanage_Checked == true &&
                              selected_new.usermanage_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize mr-6"
                      >{{ $t("View") }}</v-btn
                    >
                    <v-btn
                      :disabled="!selected_new.usermanage_Checked || editing"
                      @click="selected_new.usermanage_Permission = 1"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.usermanage_Checked == true &&
                              selected_new.usermanage_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.usermanage_Checked == true &&
                                  selected_new.usermanage_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.usermanage_Checked == false
                          ? [
                              !selected_new.usermanage_Checked == true &&
                              selected_new.usermanage_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.usermanage_Checked == true &&
                              selected_new.usermanage_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.usermanage_Checked == false &&
                                  selected_new.usermanage_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize"
                      >{{
                        $t("add") + "," + $t("edit") + "," + $t("delete")
                      }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="12" class="pl-6">
                    <v-checkbox
                      :disabled="editing"
                      v-model="selected_new.companyProfile_Checked"
                      hide-details
                      color="#a6cc39"
                      class="permission_checkbox"
                      :label="$t('companyprofile')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" style="padding-left: 70px">
                    <v-btn
                      :disabled="
                        !selected_new.companyProfile_Checked || editing
                      "
                      @click="selected_new.companyProfile_Permission = 0"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.companyProfile_Checked == true &&
                              selected_new.companyProfile_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.companyProfile_Checked ==
                                    true &&
                                  selected_new.companyProfile_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.companyProfile_Checked == false
                          ? [
                              !selected_new.companyProfile_Checked == true &&
                              selected_new.companyProfile_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.companyProfile_Checked == true &&
                              selected_new.companyProfile_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize mr-6"
                      >{{ $t("View") }}</v-btn
                    >
                    <v-btn
                      :disabled="
                        !selected_new.companyProfile_Checked || editing
                      "
                      @click="selected_new.companyProfile_Permission = 1"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.companyProfile_Checked == true &&
                              selected_new.companyProfile_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.companyProfile_Checked ==
                                    true &&
                                  selected_new.companyProfile_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.companyProfile_Checked == false
                          ? [
                              !selected_new.companyProfile_Checked == true &&
                              selected_new.companyProfile_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.companyProfile_Checked == true &&
                              selected_new.companyProfile_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.companyProfile_Checked ==
                                    false &&
                                  selected_new.companyProfile_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize"
                      >{{
                        $t("add") + "," + $t("edit") + "," + $t("delete")
                      }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12" class="pl-6">
                    <v-checkbox
                      :disabled="editing"
                      v-model="selected_new.sendnoti_Checked"
                      hide-details
                      color="#a6cc39"
                      class="permission_checkbox"
                      :label="$t('sendnotification')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" style="padding-left: 70px">
                    <v-btn
                      :disabled="!selected_new.sendnoti_Checked || editing"
                      @click="selected_new.sendnoti_Permission = 0"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.sendnoti_Checked == true &&
                              selected_new.sendnoti_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.sendnoti_Checked == true &&
                                  selected_new.sendnoti_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.sendnoti_Checked == false
                          ? [
                              !selected_new.sendnoti_Checked == true &&
                              selected_new.sendnoti_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.sendnoti_Checked == true &&
                              selected_new.sendnoti_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize mr-6"
                      >{{ $t("View") }}</v-btn
                    >
                    <v-btn
                      :disabled="!selected_new.sendnoti_Checked || editing"
                      @click="selected_new.sendnoti_Permission = 1"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.sendnoti_Checked == true &&
                              selected_new.sendnoti_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.sendnoti_Checked == true &&
                                  selected_new.sendnoti_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.sendnoti_Checked == false
                          ? [
                              !selected_new.sendnoti_Checked == true &&
                              selected_new.sendnoti_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.sendnoti_Checked == true &&
                              selected_new.sendnoti_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.sendnoti_Checked == false &&
                                  selected_new.sendnoti_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize"
                      >{{
                        $t("add") + "," + $t("edit") + "," + $t("delete")
                      }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="12" class="pl-6">
                    <v-checkbox
                      :disabled="editing"
                      v-model="selected_new.settingNoti_Checked"
                      hide-details
                      color="#a6cc39"
                      class="permission_checkbox"
                      :label="$t('setting') + ' - ' + $t('notification')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" style="padding-left: 70px">
                    <v-btn
                      :disabled="!selected_new.settingNoti_Checked || editing"
                      @click="selected_new.settingNoti_Permission = 0"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.settingNoti_Checked == true &&
                              selected_new.settingNoti_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.settingNoti_Checked == true &&
                                  selected_new.settingNoti_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.settingNoti_Checked == false
                          ? [
                              !selected_new.settingNoti_Checked == true &&
                              selected_new.settingNoti_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.settingNoti_Checked == true &&
                              selected_new.settingNoti_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize mr-6"
                      >{{ $t("View") }}</v-btn
                    >
                    <v-btn
                      :disabled="!selected_new.settingNoti_Checked || editing"
                      @click="selected_new.settingNoti_Permission = 1"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.settingNoti_Checked == true &&
                              selected_new.settingNoti_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.settingNoti_Checked == true &&
                                  selected_new.settingNoti_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : selected_new.settingNoti_Checked == false
                          ? [
                              !selected_new.settingNoti_Checked == true &&
                              selected_new.settingNoti_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.settingNoti_Checked == true &&
                              selected_new.settingNoti_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.settingNoti_Checked == false &&
                                  selected_new.settingNoti_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize"
                      >{{
                        $t("add") + "," + $t("edit") + "," + $t("delete")
                      }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12" class="pl-6">
                    <v-checkbox
                      :disabled="editing"
                      v-model="selected_new.store_Checked"
                      hide-details
                      color="#a6cc39"
                      class="permission_checkbox"
                      :label="$t('store')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" style="padding-left: 70px">
                    <v-btn
                      :disabled="!selected_new.store_Checked || editing"
                      @click="selected_new.store_Permission = 0"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.store_Checked == true && selected_new.store_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.store_Checked == true &&
                                  selected_new.store_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : editing == false && selected_new.store_Checked == false
                          ? [
                              !selected_new.store_Checked == true && selected_new.store_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.store_Checked == true && selected_new.store_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize mr-6"
                      >{{ $t("View") }}</v-btn
                    >
                    <v-btn
                      :disabled="!selected_new.store_Checked || editing"
                      @click="selected_new.store_Permission = 1"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.store_Checked == true && selected_new.store_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.store_Checked == true &&
                                  selected_new.store_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : editing == false && selected_new.store_Checked == false
                          ? [
                              !selected_new.store_Checked == true && selected_new.store_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.store_Checked == true && selected_new.store_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.store_Checked == false &&
                                  selected_new.store_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize"
                      >{{ $t("add") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="12" class="pl-6">
                    <v-checkbox
                      :disabled="editing"
                      v-model="selected_new.dashboard_Checked"
                      hide-details
                      color="#a6cc39"
                      class="permission_checkbox"
                      :label="$t('dashboard')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" style="padding-left: 70px">
                    <v-btn
                      :disabled="!selected_new.dashboard_Checked || editing"
                      @click="selected_new.dashboard_Permission = 0"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.dashboard_Checked == true &&
                              selected_new.dashboard_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.dashboard_Checked == true &&
                                  selected_new.dashboard_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : editing == false && selected_new.dashboard_Checked == false
                          ? [
                              !selected_new.dashboard_Checked == true &&
                              selected_new.dashboard_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.dashboard_Checked == true &&
                              selected_new.dashboard_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize mr-6"
                      >{{ $t("View") }}</v-btn
                    >
                    <v-btn
                      :disabled="!selected_new.dashboard_Checked || editing"
                      @click="selected_new.dashboard_Permission = 1"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.dashboard_Checked == true &&
                              selected_new.dashboard_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.dashboard_Checked == true &&
                                  selected_new.dashboard_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : editing == false && selected_new.dashboard_Checked == false
                          ? [
                              !selected_new.dashboard_Checked == true &&
                              selected_new.dashboard_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.dashboard_Checked == true &&
                              selected_new.dashboard_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.dashboard_Checked == false &&
                                  selected_new.dashboard_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize"
                      >{{ $t("filter") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12" class="pl-6">
                    <v-checkbox
                      :disabled="editing"
                      v-model="selected_new.skillMatrix_Checked"
                      hide-details
                      color="#a6cc39"
                      class="permission_checkbox"
                      :label="$t('trainingroadmapiso')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" style="padding-left: 70px">
                    <v-btn
                      :disabled="!selected_new.skillMatrix_Checked || editing"
                      @click="selected_new.skillMatrix_Permission = 0"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.skillMatrix_Checked == true &&
                              selected_new.skillMatrix_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.skillMatrix_Checked == true &&
                                  selected_new.skillMatrix_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : editing == false && selected_new.skillMatrix_Checked == false
                          ? [
                              !selected_new.skillMatrix_Checked == true &&
                              selected_new.skillMatrix_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.skillMatrix_Checked == true &&
                              selected_new.skillMatrix_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 1
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 1
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize mr-6"
                      >{{ $t("View") }}</v-btn
                    >
                    <v-btn
                      :disabled="!selected_new.skillMatrix_Checked || editing"
                      @click="selected_new.skillMatrix_Permission = 1"
                      style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                      :style="
                        editing == false
                          ? [
                              !selected_new.skillMatrix_Checked == true &&
                              selected_new.skillMatrix_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.skillMatrix_Checked == true &&
                                  selected_new.skillMatrix_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : editing == false && selected_new.skillMatrix_Checked == false
                          ? [
                              !selected_new.skillMatrix_Checked == true &&
                              selected_new.skillMatrix_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : !selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                          : [
                              selected_new.skillMatrix_Checked == true &&
                              selected_new.skillMatrix_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 1
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 0
                                ? {
                                    'background-color': '#0000001f !important',
                                    color: '#424242 !important',
                                  }
                                : selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 0
                                ? {
                                    'background-color': '#ffffff',
                                    color: '#A6CC39',
                                    border: '1px solid #A6CC39',
                                  }
                                : !selected_new.skillMatrix_Checked == false &&
                                  selected_new.skillMatrix_Permission == 0
                                ? {
                                    'background-color': '#ffffff !important',
                                    color: '#424242 !important',
                                    border: '1px solid #CCCCCC !important',
                                  }
                                : {
                                    'background-color': '#A6CC39',
                                    color: '#ffffff',
                                  },
                            ]
                      "
                      class="text-capitalize"
                      >{{
                        $t("add") + "," + $t("edit") + "," + $t("delete")
                      }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col></v-col>
            </v-row>
            <v-divider
              style="border: 1px solid #a6cc39"
              class="mt-7 mb-6 mr-3"
            ></v-divider>
            <v-row class="mb-0">
              <v-col cols="12" class="pl-3">
                <span style="font-size: 20px; color: #424242">{{
                  $t("reports")
                }}</span>
              </v-col>
              <v-col cols="4" class="pt-0 pl-6">
                <v-checkbox
                  :disabled="editing"
                  v-model="selected_new.userActivity_Checked"
                  class="permission_checkbox mr-3"
                  :label="$t('useractivityrp')"
                  color="#a6cc39"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="4" class="pt-0">
                <v-checkbox
                  :disabled="editing"
                  v-model="selected_new.userView_Checked"
                  class="permission_checkbox mr-3"
                  :label="$t('contentviewrp')"
                  color="#a6cc39"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="4" class="pt-0 pl-6">
                <v-checkbox
                  :disabled="editing"
                  v-model="selected_new.requestedUserRp_Checked"
                  class="permission_checkbox mr-3"
                  :label="$t('requesteduserreport')"
                  color="#a6cc39"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="pt-0 pl-6">
                <v-checkbox
                  :disabled="editing"
                  v-model="selected_new.acReport_Checked"
                  class="permission_checkbox"
                  :label="$t('ACReport')"
                  color="#a6cc39"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions
          class="mt-0 pt-2 pb-3"
          style="height: 70px; border-top: 1px solid #c0c0c05c"
        >
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-3 text-capitalize"
            style="
              font-size: 18px;
              color: #424242;
              border: 1px solid #c0c0c0;
              border-radius: 8px;
            "
            @click="
              $emit('close'),
                $store.commit(
                  editLevel ? 'openLevelEditDialog' : 'openLevelAddDialog'
                )
            "
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            width="110"
            style="
              font-size: 18px;
              color: #ffffff;
              border-radius: 8px;
              box-shadow: unset;
            "
            class="text-capitalize"
            color="#A6CC39"
            @click="
              $emit('close'),
                $store.commit(
                  editLevel ? 'openLevelEditDialog' : 'openLevelAddDialog'
                ),
                $emit('updatePermissions', selected_new),
                $store.commit('saveSelectedLevelPermission', selected_new)
            "
            >{{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    editing: false,
    valid: true,
    selected_new: {
      mobileOrWebUsage_Permission: 1, //1 is default web+mobile type.2 is mobile only type.

      //Remove R11 feature > 1 == all, 0 == group
      // quiz_View: 1,
      // quiz_Crud: 1,
      // course_View: 1,
      // course_Crud: 1,

      userActivity_Checked: true,
      // usageMetrics_Checked: true,
      userView_Checked: true,
      requestedUserRp_Checked: true,
      acReport_Checked: true,

      library_Checked: true,
      lessonlearn_Checked: true,
      course_Checked: true,
      quiz_Checked: true,
      community_Checked: true,
      event_Checked: true,
      banner_Checked: true,
      feedbackbox_Checked: true,
      usermanage_Checked: true,
      companyProfile_Checked: true,
      sendnoti_Checked: true,
      settingNoti_Checked: true,
      store_Checked: true,
      skillMatrix_Checked: true,
      dashboard_Checked: true,

      library_Permission: 0,
      lessonLearn_Permission: 0,
      course_Permission: 0,
      quiz_Permission: 0,
      community_Permission: 0,
      event_Permission: 0,
      banner_Permission: 0,
      feedbackbox_Permission: 0,
      usermanage_Permission: 0,
      companyProfile_Permission: 0,
      sendnoti_Permission: 0,
      settingNoti_Permission: 0,
      skillMatrix_Permission: 0,
      store_Permission: 0,
      dashboard_Permission: 0,
    },
  }),
  computed: {
    ...mapGetters(["selectedLevelPermission", "editLevel"]),
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    // headers() {
    //   return [
    //     {
    //       text: "PERMISSION ID",
    //       align: "center",
    //       value: "permissionID",
    //       class: "tableColor"
    //     },
    //     {
    //       text: "PERMISSION NAME",
    //       align: "left",
    //       value: "permissionName",
    //       class: "tableColor"
    //     }
    //   ];
    // }
  },
  mounted() {
    this.getPermission();
  },
  methods: {
    //data is carried by this.selected and save in vuex
    //use $store.commit('saveSelectedLevelPermission', selected); for vuex save
    //to get the above saved vuex data use => this.selectedLevelPermission

    //R11 permissions data from permission Tab
    //Flow -> new role add's permissions data must be same as permission default setting tab
    async getPermission() {
      let self = this;
      self.loading = true;

      let checkPermission = true;
      if (checkPermission) {
        const res = await self.$axios.post(
          `${self.web_url}Permission/GetPermissionSetting?CompanyID=` +
            localStorage.getItem("companyID")
        );
        if (res.data.status == 0) {
          if (res.data.data != null) {
            this.selected_new = {
              createBy: res.data.data.createBy,
              createDate: res.data.data.createDate,
              updateBy: res.data.data.updateBy,
              updateDate: res.data.data.updateDate,

              permissionDefaultSetting_id: res.data.data.permissionSettingID,
              mobileOrWebUsage_Permission: res.data.data.permissionID, //usageID 1(web+mobile) 0r 2(mobile only)

              //data from db work as below
              //eg.menu's Flag == true (>menu is checked) or false (>menu is unchecked).This menu will not show.
              //eg.menu's View == true > crud permission is on, otherwise only view permission is on.
              //menu (in web data) => 1 is crud, 0 is only view permission.
              library_Checked: res.data.data.libraryFlag,
              library_Permission: res.data.data.libraryView === true ? 1 : 0,

              lessonlearn_Checked: res.data.data.lessonLearnFlag,
              lessonLearn_Permission:
                res.data.data.lessonLearnView === true ? 1 : 0,

              course_Checked: res.data.data.courseFlag,
              course_Permission: res.data.data.courseView === true ? 1 : 0,

              // course_Crud: 1,
              // course_View: 1,

              quiz_Checked: res.data.data.quizFlag,
              quiz_Permission: res.data.data.quizView === true ? 1 : 0,

              // quiz_Crud: 1,
              // quiz_View: 1,

              community_Checked: res.data.data.communityFlag,
              community_Permission:
                res.data.data.communityView === true ? 1 : 0,

              event_Checked: res.data.data.eventFlag,
              event_Permission: res.data.data.eventView === true ? 1 : 0,

              banner_Checked: res.data.data.bannerFlag,
              banner_Permission: res.data.data.bannerView === true ? 1 : 0,

              feedbackbox_Checked: res.data.data.feedbackFlag,
              feedbackbox_Permission:
                res.data.data.feedbackView === true ? 1 : 0,

              usermanage_Checked: res.data.data.userManagementFlag,
              usermanage_Permission:
                res.data.data.userManagementView === true ? 1 : 0,

              companyProfile_Checked: res.data.data.companyProfileFlag,
              companyProfile_Permission:
                res.data.data.companyProfileView === true ? 1 : 0,

              sendnoti_Checked: res.data.data.sendNotificationFlag,
              sendnoti_Permission:
                res.data.data.sendNotificationView === true ? 1 : 0,

              settingNoti_Checked: res.data.data.settingNotificationFlag,
              settingNoti_Permission:
                res.data.data.settingNotificationView === true ? 1 : 0,

              store_Checked: res.data.data.storeFlag,
              store_Permission: res.data.data.storeView === true ? 1 : 0,

              skillMatrix_Checked: res.data.data.skillMatrixFlag,
              skillMatrix_Permission: res.data.data.skillMatrixView === true ? 1 : 0,

              dashboard_Checked: res.data.data.dashboardFlag,
              dashboard_Permission: res.data.data.dashboardView === true ? 1 : 0,

              userActivity_Checked: res.data.data.userActivityFlag,
              // usageMetrics_Checked: res.data.data.usageMetricsFlag,
              userView_Checked: res.data.data.userViewFlag,
              requestedUserRp_Checked: res.data.data.requestedUserFlag,
              acReport_Checked: res.data.data.assignmentReportFlag,
            };
          }
          this.editing =
            this.selected_new.mobileOrWebUsage_Permission === 2; //? true : false;
        }
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to see Permission Data.";
      }
      self.loading = false;
    },

    //Permission flow table data before R11
    // getSelectAll() {
    //   if (this.selected.length == 0) {
    //     this.selected = this.items;
    //   } else {
    //     this.selected = [];
    //   }
    // },
    //Permission work flow before R11, which has only Admin & User mobile that showed in table and choose between them
    // async getPermission() {
    //   this.loading = true;
    //   const res = await this.$axios.get(
    //     `${this.web_url}Permission/GetPermissionAll`
    //   );
    //   this.items = res.data.data;
    //   this.loading = false;
    // },

    getSelected() {
      this.selected = this.selectedLevelPermission;
    },
  },
};
</script>

<style scoped>
::v-deep .v-sheet.v-card {
  border-radius: 34px !important;
}
::v-deep .permission_checkbox.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

/* For scroll bar in form -- show */
::v-deep .v-form::-webkit-scrollbar {
  width: 3px !important;
  border-radius: 10px !important;
}
::v-deep .v-form::-webkit-scrollbar-track {
  width: 3px !important;
  background: #c0c0c05c;
  border-radius: 10px !important;
}
::v-deep .v-form::-webkit-scrollbar-thumb {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  background-color: transparent;
  border-top: 60px solid #424242;
  border-bottom: 60px solid #424242;
}

/* For scroll bar in dialog -- hide */
::v-deep .v-dialog--scrollable > .v-card > .v-card__text::-webkit-scrollbar {
  width: 0px !important;
  background: #e6e6e6;
}
::v-deep
  .v-dialog--scrollable
  > .v-card
  > .v-card__text::-webkit-scrollbar-track {
  width: 0px !important;
  background: #e6e6e6;
}
::v-deep
  .v-dialog--scrollable
  > .v-card
  > .v-card__text::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 1px #e6e6e6;
}
/* >>> .v-dialog {
  overflow-y: hidden !important;
  overflow: hidden !important;
} */

/* css used before R11.... */
/* >>> .v-input--selection-controls__input .v-icon {
  color: #a6cc39 !important;
  border-radius: 4px;
  opacity: 1;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 20px !important;
  color: #47484b !important;
  background-color: #dbdbda !important;
  opacity: 1;
}
>>> .permission-table tr:nth-child(even) {
  background: #e0e0e0;
}
>>> .pagination .primary {
  background-color: #e8e9ec !important;
  color: #4d4f5c !important;
  opacity: 1;
} */
</style>