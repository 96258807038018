<template>
  <div>
    <v-dialog width="600" v-model="dialog">
      <v-card>
        <v-card-title >
          <v-row  no-gutters style="font-size:30px"> {{$t('deleteconfirm')}} </v-row>
        </v-card-title>
        <v-card-text>
          <p style="font-size:20px" class="pt-5 text-center" v-if="item">
            {{$t('suredelete')}} "Role ID {{ item.roleCode }}" ?
          </p>
        </v-card-text>
        <v-card-actions v-if="item.haveDepartment == true">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="text-capitalize"
            style="font-size:20px"
            width="100"
            @click="$emit('close')"
            >{{$t('close')}}</v-btn
          >
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize mr-3"
            style="font-size:20px"
            width="100"
            text
            @click="$emit('close')"
            >{{$t('cancel')}}</v-btn
          >
          <v-btn
            color="error"
            class="text-capitalize"
            style="font-size:20px"
            width="100"
            @click="submit()"
            >{{$t('confirm')}}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },
  methods: {
    async getRoleAndPermission() {
      const res = await this.$axios.post(
        `${this.web_url}RoleAndPermission/GetRoleForUserAndGroup?companyid=` +
          localStorage.getItem("companyID")
      );
      this.$store.commit("saveroleList", res.data.data);
    },
    async submit() {
      const res = await this.$axios.post(
        `${this.web_url}RoleAndPermission/DeleteUserRole?id=` +
          this.item.userRoleID
      );
      if (res.data.status == 0) {
        this.$emit("confirm");  
        this.getRoleAndPermission()
      }
    },
  },
};
</script>
<style scoped>
::v-deep .v-dialog {
  border-radius: 16px !important;
}
</style>