<template>
  <div>
    <Breadcrumbs
      :items="breadcrumbs"
      page_title="usermanagement"
      :item_no="item_No ? item_No : 0"
    />
    <v-container>
      <v-card class="rounded-lg">
        <v-container>
          <v-toolbar class="elevation-0">
            <v-tabs
              v-if="
                !showDepartmentTable &&
                !showPermissionTable &&
                !showDepartmentOneTable
              "
              v-model="btnTabs"
              class="tab-mobileres"
              color="secondary"
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                tag
                class="text-capitalize"
                style="font-size: 20px; width: 130px"
                @click="tab1Click()"
              >
                <v-icon style="font-size: 30px">mdi-account-outline</v-icon>
                {{ $t("user") }}
              </v-tab>

              <v-tab
                class="text-capitalize"
                style="font-size: 20px; width: 150px"
                @click="tab2Click()"
              >
                <v-icon style="font-size: 30px; padding-right: 5px;"
                  >mdi-account-group-outline</v-icon
                >
                {{ $t("group") }}
              </v-tab>
              <v-tab
                class="text-capitalize"
                style="font-size: 20px; width: 170px"
                @click="tab5Click()"
              >
                <v-img
                  v-if="this.departmentone == true"
                  src="@/assets/images/department-line-green.svg"
                  width="28"
                  height="28"
                  class="mr-0"
                ></v-img>
                <v-img
                  v-else
                  src="@/assets/images/department-line.svg"
                  width="28"
                  height="28"
                  class="mr-0"
                ></v-img>
                {{ $t("departmentl") }}
              </v-tab>
              <v-tab
                class="text-capitalize"
                style="font-size: 20px; width: 130px"
                @click="tab3Click()"
              >
                <v-icon style="font-size: 30px"
                  >mdi-account-arrow-up-outline</v-icon
                >
                {{ $t("role") }}
              </v-tab>

              <v-tab
                class="text-capitalize"
                style="font-size: 20px"
                @click="tab4Click()"
              >
                <v-icon style="font-size: 30px"
                  >mdi-account-lock-outline</v-icon
                >
                {{ $t("permission") }}
              </v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>
          <v-row class="d-flex mt-3 mx-0">
            <v-col cols="3" md="4" lg="3">
              <v-text-field
                outlined
                dense
                clearable
                style="border-radius: 8px"
                prepend-inner-icon="mdi-magnify"
                :placeholder="$t('search')"
                v-if="showAddNewBtn"
                v-model="search_"
              ></v-text-field>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn
                v-if="showImportUserBtn"
                outlined
                depressed
                style="font-size: 16px; border: 1px solid #c0c0c0"
                class="mr-3 text-capitalize rounded-lg"
                @click="ImportNew()"
              >
                <v-icon>mdi-account-plus-outline</v-icon>
                {{ $t("importuser") }}
              </v-btn>
              <v-btn
                v-if="showAddNewBtn"
                outlined
                depressed
                color="secondary"
                style="font-size: 16px; border: 1px solid #c0c0c0"
                class="mr-3 text-capitalize rounded-lg"
                @click="AddNew(addNewBtnText)"
              >
                <v-icon>mdi-plus</v-icon>
                {{ $t(addNewBtnText) }}
              </v-btn>
              <v-btn
                v-if="showImportUserBtn"
                outlined
                @click="exportExcel()"
                style="
                  color: #a6cc39;
                  font-size: 16px;
                  border: 1px solid #c0c0c0;
                  box-shadow: unset !important;
                  border-radius: 8px;
                  background: #ffffff 0% 0% no-repeat padding-box;
                "
                class="text-capitalize"
              >
                <v-icon class="mr-2 mb-1">mdi-export-variant</v-icon>
                {{ $t("exportexcel") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-tabs-items v-model="tabs">
            <v-tab-item value="tab-1">
              <User
                class="mt-3"
                ref="UserComponent"
                :search="search_"
                @itemsCount="getItem_No"
              />
            </v-tab-item>
            <v-tab-item value="tab-2">
              <Department
                class="mt-3"
                ref="GroupComponent"
                :search="search_"
                @itemsCount="getItem_No"
              />
            </v-tab-item>
            <v-tab-item value="tab-4">
              <Permission class="mt-3" ref="PermissionComponent" />
            </v-tab-item>
            <v-tab-item value="tab-3">
              <Level
                class="mt-3"
                ref="RoleComponent"
                :search="search_"
                @itemsCount="getItem_No"
                @update_Permission="updatePermission_emit"
              />
            </v-tab-item>
            <v-tab-item value="tab-5">
              <DepartmentOne
                class="mt-4"
                ref="DeptComponent"
                :search="search_"
                @itemsCount="getItem_No"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-card>
    </v-container>

    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </div>
</template>

<script>
import User from "./User/Index.vue";
import Department from "./Group/Index.vue";
import Permission from "./Permission/Index.vue";
import Level from "./Level/Index.vue";
import DepartmentOne from "./DepartmentOne/Index.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    User,
    Department,
    Permission,
    Level,
    DepartmentOne,
  },
  data() {
    return {
      usetItem: 0,
      groupItem: 0,
      roleItem: 0,
      deptItem: 0,
      permissionItem: 0,
      item_No: 0,
      search_: null,
      //  showAddNewBtn: true,
      showImportUserBtn: true,
      addNewBtnText: "newuser",
      permissionDialog: false,
      permissionMessage: "",
      breadcrumbs: [
        {
          //image: "house.png"
          sidebar_tilte: "usermanagement", //home
          href: "/home/user-management",
        },
      ],
      departmentone: false,
    };
  },
  watch: {
    addNewBtnText: {
      handler: function (val) {
        if (val == "newuser") {
          this.breadcrumbs = [
            {
              sidebar_tilte: "usermanagement", //home
            },
            {
              text: "user",
            },
          ];
        } else if (val == "newdepartment") {
          this.breadcrumbs = [
            {
              sidebar_tilte: "usermanagement", //home
            },
            {
              text: "group",
            },
          ];
        } else if (val == "newlevel") {
          this.breadcrumbs = [
            {
              sidebar_tilte: "usermanagement", //home
            },
            {
              text: "role",
            },
          ];
        } else if (val == "permission") {
          this.breadcrumbs = [
            {
              sidebar_tilte: "usermanagement", //home
            },
            {
              text: "permission",
            },
          ];
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      "showDepartmentTable",
      "showPermissionTable",
      "SearchandBtnFlag",
      "showDepartmentOneTable",
    ]),
    tabs() {
      return this.$store.getters.tabs;
    },
    btnTabs: {
      get() {
        if (this.$store.getters.tabs == "tab-1") {
          return 0;
        } else if (this.$store.getters.tabs == "tab-2") {
          return 1;
        } else if (this.$store.getters.tabs == "tab-5") {
          return 2;
        } else if (this.$store.getters.tabs == "tab-3") {
          return 3;
        } else {
          return 4;
        }
      },
      set() {},
    },
    showAddNewBtn: {
      get() {
        return this.SearchandBtnFlag;
      },
      set() {
        return this.SearchandBtnFlag;
      },
    },
  },
  methods: {
    async tab1Click() {
      // User Tab
      let self = this;

      self.departmentone = false;
      self.addNewBtnText = "newuser";
      self.showAddNewBtn = true;
      self.$store.commit("saveSearchandBtnFlag", true);
      self.showImportUserBtn = true;
      self.item_No = self.usetItem;

      self.search_ = null;
      self.$store.commit("changeTab", "tab-1");
      let checkPermission = true;
      if (!checkPermission) {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to see User Data.";
      }
      if (self.usetItem != 0) {
        self.$refs.UserComponent.getUserData();
      }
    },
    async tab2Click() {
      // Group Tab
      let self = this;

      self.departmentone = false;
      self.addNewBtnText = "newdepartment";
      self.showAddNewBtn = true;
      self.$store.commit("saveSearchandBtnFlag", true);
      self.showImportUserBtn = false;
      self.item_No = self.groupItem;

      self.search_ = null;
      self.$store.commit("changeTab", "tab-2");
      let checkPermission = true;
      if (!checkPermission) {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to see Group Data.";
      }
      if (self.groupItem != 0) {
        self.$refs.GroupComponent.getGroup();
      }
    },
    async tab3Click() {
      // Role Tab
      let self = this;

      self.departmentone = false;
      self.addNewBtnText = "newlevel";
      self.showAddNewBtn = true;
      self.$store.commit("saveSearchandBtnFlag", true);
      self.showImportUserBtn = false;
      self.item_No = self.roleItem;

      self.search_ = null;
      self.$store.commit("changeTab", "tab-3");
      let checkPermission = true;
      if (!checkPermission) {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to see Role Data.";
      }
    },
    async tab4Click() {
      // Permission Tab
      let self = this;

      self.departmentone = false;
      self.addNewBtnText = "permission";
      self.showAddNewBtn = false;
      self.showImportUserBtn = false;
      self.item_No = self.permissionItem;
      self.$store.commit("saveSearchandBtnFlag", false);
      self.search_ = null;
      self.$store.commit("changeTab", "tab-4");
      let checkPermission = true;
      if (!checkPermission) {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to see Permission Data.";
      }
    },
    async tab5Click() {
      // Department Tab
      let self = this;

      self.departmentone = true;
      self.addNewBtnText = "newdept";
      self.showAddNewBtn = true;
      self.$store.commit("saveSearchandBtnFlag", true);
      self.showImportUserBtn = false;
      self.item_No = self.deptItem;

      self.search_ = null;
      self.$store.commit("changeTab", "tab-5");
      let checkPermission = true;
      if (!checkPermission) {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to see Department Data.";
      }
      if (self.deptItem != 0) {
        self.$refs.DeptComponent.getDepartment();
      }
    },
    AddNew(v) {
      if (v == "newuser") {
        this.$refs.UserComponent.checkUserAdd();
      } else if (v == "newdepartment") {
        this.$refs.GroupComponent.checkDepartmentAdd();
      } else if (v == "newlevel") {
        this.$refs.RoleComponent.checkLevelAdd();
      } else if (v == "newdept") {
        this.$refs.DeptComponent.checkNoDepartmentAdd();
      }
    },
    ImportNew() {
      //only uset tab got import function..
      this.$refs.UserComponent.checkUserAddImport();
    },
    updatePermission_emit() {
      //emitting update permissions method call...
      this.$emit("updateMethodCall");
    },
    getItem_No(value) {
      this.item_No = value;
      if (this.addNewBtnText == "newuser") {
        this.usetItem = value;
      } else if (this.addNewBtnText == "newdepartment") {
        this.groupItem = value;        
      } else if (this.addNewBtnText == "newlevel") {
        this.roleItem = value;
      } else if (this.addNewBtnText == "newdept") {
        this.deptItem = value;
      }
    },
    exportExcel() {
      this.$refs.UserComponent.exportExcel();
    },
  },
  mounted() {
    //in case user exist userlist page from back arrow, make tabs to not disappear
    this.$store.commit("EditDepartmentTable", false);
    this.$store.commit("EditDepartmentOneTable", false);
    this.$store.commit("DepartmentTable", false);
    //................

    this.tab1Click();
    this.breadcrumbs = [
      {
        sidebar_tilte: "usermanagement",
      },
      {
        text: "user",
      },
    ];
  },
};
</script>
<style scoped>
::v-deep .v-image__image--cover {
  background-size: auto;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .tab-mobileres {
    width: 300px;
    overflow: auto;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .tab-mobileres {
    width: 500px;
    overflow: auto;
  }
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
</style>

<style>
.v-tabs-slider-wrapper {
  height: 3px !important;
}
@media (min-width: 1264px) {
  .container {
    max-width: unset !important;
    /* margin-top: 1.5rem !important; */
  }
}
.v-pagination__navigation {
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid #c0c0c0;
}
.v-pagination__item {
  background: transparent !important;
  box-shadow: none !important;
}
.v-card {
  padding: 0px !important;
}
</style>
