<template>
  <div>
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title style="font-size: 20px; height: 70px">
            <p>
              <v-icon class="mb-1 mr-1" style="font-size: 25px; color: #424242"
                >mdi-account-plus-outline</v-icon
              >
              {{ $t("newlevel") }}
            </p>

            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="$emit('close')"
              color="#424242"
              style="
                border-radius: 8px !important;
                border: 1px solid rgba(66, 66, 66, 0.05);
                margin-bottom: 16px;
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row class="pt-5">
              <v-col cols="9">
                <label for="levelid" class="font-style">
                  {{ $t("levelid") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  :rules="levelidRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="level.levelId"
                ></v-text-field>
              </v-col>
              <v-col cols="2"></v-col>
              <v-col cols="9">
                <label for="levelnamesm" class="font-style">
                  {{ $t("levelnamesm") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  outlined
                  :rules="levelRules"
                  dense
                  hide-details="auto"
                  v-model="level.levelName"
                ></v-text-field>
              </v-col>
              <v-col cols="2"></v-col>
              <v-col cols="9">
                <label for="permission" class="font-style">
                  {{ $t("permission") }}
                  <span style="color: red">*</span>
                </label>
                <v-combobox
                  multiple
                  hide-selected
                  readonly
                  append-icon
                  :items="permissions"
                  :rules="permissionRules"
                  item-text="permissionName"
                  item-value="permissionID"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="level.permission"
                ></v-combobox>
              </v-col>
              <v-col cols="3">
                <v-btn
                  width="90"
                  color="secondary"
                  class="text-capitalize mt-7 font-style"
                  block
                  @click="
                    $emit('close'),
                      (showPermissionDialog = true),
                      $refs.updatePermission.getPermission()
                  "
                  >{{ $t("add") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="mt-3 pb-5">
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize btnfont-style"
              text
              outlined
              @click="
                $emit('close'),
                  $store.commit('removeSelectedLevelPermission'),
                  (level.permission = []),
                  $refs.form.reset()
              "
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="text-capitalize btnfont-style"
              color="secondary"
              width="100"
              @click="submit()"
              >{{ $t("save") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <PermissionAdd
      ref="updatePermission"
      :show="showPermissionDialog"
      @close="(showPermissionDialog = false), (dialog = true)"
      @updatePermissions="getSelectedPermission()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PermissionAdd from "./PermissionAdd.vue";
export default {
  components: {
    PermissionAdd,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["selectedLevelPermission"]),
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    selected_new() {
      //selected
      return this.selectedLevelPermission;
    },
  },
  data: () => ({
    showPermissionDialog: false,
    level: {
      levelId: "",
      levelName: "",
      permission: null,
    },
    levelidRules: [(v) => !!v || "Level ID is required"],
    levelRules: [(v) => !!v || "Level Name is required"],
    permissionRules: [(v) => !!v || "Permission is required"],
    valid: true,
    permissions: [],
  }),
  watch: {
    selected_new(val) {
      //selected
      if (val) {
        this.getSelectedPermission();
      }
    },
  },
  mounted() {
    this.getPermission();
  },
  methods: {
    async getRoleAndPermission() {
      const res = await this.$axios.post(
        `${this.web_url}RoleAndPermission/GetRoleForUserAndGroup?companyid=` +
          localStorage.getItem("companyID")
      );
      this.$store.commit("saveroleList", res.data.data);
    },

    //below method is old permission flow before R11
    //its for Mobile Only 0r Web + Mobile to choose...
    async getPermission() {
      const res = await this.$axios.get(
        `${this.web_url}Permission/GetPermissionAll`
      );
      this.permissions = res.data.data;
    },

    getSelectedPermission() {
      let per = [];
      let per_type =
        this.selectedLevelPermission.mobileOrWebUsage_Permission == 1
          ? "All"
          : "Userview + App";
      per.push(per_type);
      if (this.selectedLevelPermission.length == 0) {
        this.level.permission = [];
      } else {
        this.level.permission = per;
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        let permission_data = {
          userRoleID: 0,
          permissionID:
            this.selectedLevelPermission.mobileOrWebUsage_Permission, //usageID 1(web+mobile) 0r 2(mobile only)

          createBy: localStorage.getItem("userName"),
          updateBy: null,

          //menus => 1 is crud, 0 is only view permission.
          libraryFlag: this.selectedLevelPermission.library_Checked,
          libraryView:
            this.selectedLevelPermission.library_Permission == 1 ? true : false,

          lessonLearnFlag: this.selectedLevelPermission.lessonlearn_Checked,
          lessonLearnView:
            this.selectedLevelPermission.lessonLearn_Permission == 1
              ? true
              : false,

          courseFlag: this.selectedLevelPermission.course_Checked,
          courseView:
            this.selectedLevelPermission.course_Permission == 1 ? true : false,

          quizFlag: this.selectedLevelPermission.quiz_Checked,
          quizView:
            this.selectedLevelPermission.quiz_Permission == 1 ? true : false,

          communityFlag: this.selectedLevelPermission.community_Checked,
          communityView:
            this.selectedLevelPermission.community_Permission == 1
              ? true
              : false,

          eventFlag: this.selectedLevelPermission.event_Checked,
          eventView:
            this.selectedLevelPermission.event_Permission == 1 ? true : false,

          bannerFlag: this.selectedLevelPermission.banner_Checked,
          bannerView:
            this.selectedLevelPermission.banner_Permission == 1 ? true : false,

          feedbackFlag: this.selectedLevelPermission.feedbackbox_Checked,
          feedbackView:
            this.selectedLevelPermission.feedbackbox_Permission == 1
              ? true
              : false,

          userManagementFlag: this.selectedLevelPermission.usermanage_Checked,
          userManagementView:
            this.selectedLevelPermission.usermanage_Permission == 1
              ? true
              : false,

          companyProfileFlag:
            this.selectedLevelPermission.companyProfile_Checked,
          companyProfileView:
            this.selectedLevelPermission.companyProfile_Permission == 1
              ? true
              : false,

          sendNotificationFlag: this.selectedLevelPermission.sendnoti_Checked,
          sendNotificationView:
            this.selectedLevelPermission.sendnoti_Permission == 1
              ? true
              : false,

          settingNotificationFlag:
            this.selectedLevelPermission.settingNoti_Checked,
          settingNotificationView:
            this.selectedLevelPermission.settingNoti_Permission == 1
              ? true
              : false,

          storeFlag: this.selectedLevelPermission.store_Checked,
          storeView:
            this.selectedLevelPermission.store_Permission == 1 ? true : false,

          skillMatrixFlag: this.selectedLevelPermission.skillMatrix_Checked,
          skillMatrixView:
            this.selectedLevelPermission.skillMatrix_Permission == 1
              ? true
              : false,

          dashboardFlag: this.selectedLevelPermission.dashboard_Checked,
          dashboardView:
            this.selectedLevelPermission.dashboard_Permission == 1
              ? true
              : false,

          userActivityFlag: this.selectedLevelPermission.userActivity_Checked,
          usageMetricsFlag: this.selectedLevelPermission.usageMetrics_Checked,
          userViewFlag: this.selectedLevelPermission.userView_Checked,
          requestedUserFlag:
            this.selectedLevelPermission.requestedUserRp_Checked,
          assignmentReportFlag: this.selectedLevelPermission.acReport_Checked,
        };
        const res = await this.$axios.post(
          `${this.web_url}RoleAndPermission/AddUserRole`,
          {
            companyId: localStorage.getItem("companyID"),
            roleCode: this.level.levelId,
            roleName: this.level.levelName,
            userRolePermission: permission_data,
          }
        );
        if (res.status == 200) {
          this.$emit("confirm");
          this.level = {};
          this.getRoleAndPermission();
        }
        this.$refs.form.reset();
      }
    },
  },
};
</script>
<style scoped>
::v-deep .v-dialog {
  border-radius: 35px !important;
}
.font-style {
  font-size: 16px;
  color: #4d4f5c;
}
.btnfont-style {
  font-size: 16px;
}
::v-deep .v-label {
  font-size: 16px !important;
  color: #4d4f5c !important;
}
::v-deep .v-input__slot {
  font-size: 16px !important;
  border-radius: 10px !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important ;
}
</style>
