<template>
  <v-container>
    <div>
      <v-data-table
        :loading="loading"
        loading-text="Loading... Please wait"
        :headers="headers"
        :items="filteredData"
        :search="search"
        hide-default-footer
        @page-count="pageCount = $event"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        class="user-table"
      >
        <template v-slot:item.checkstatus="{ item }">
          <div class="d-flex">
            <span
              v-if="item.lock"
              style="font-size: 16px; color: #a6cc39"
              class="text-capitalize"
            >
              {{ $t("active") }}
            </span>
            <span
              v-else
              style="font-size: 16px; color: #d52a2a"
              class="text-capitalize"
            >
              {{ $t("inactive") }}
            </span>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex" style="margin-top: -4px">
            <v-btn
              color="#4FB14E"
              style="font-size: 16px"
              text
              class="text-capitalize"
              @click="checkUserEdit(item)"
              ><v-icon>mdi-square-edit-outline</v-icon>{{ $t("edit") }}</v-btn
            >
            <v-btn
              color="#FF6060"
              text
              class="text-capitalize"
              style="font-size: 16px"
              v-if="item.positionID != 'System Administrator'"
              @click="checkUserDelete(item)"
              ><v-icon>mdi-delete-outline</v-icon>{{ $t("delete") }}</v-btn
            >
          </div>
        </template>
      </v-data-table>

      <v-row no-gutters class="d-flex justify-space-between mt-2">
        <v-col auto class="d-flex align-center">
          <div class="d-flex text-left">
            <v-pagination
              circle
              class="pagination"
              v-model="page"
              :length="pageCount"
            ></v-pagination>
          </div>
        </v-col>

        <v-col cols="12" lg="2" md="3" sm="4" class="d-flex justify-end align-center">
          <p class="mt-3 pr-4">{{ $t("Show") }}</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                width="70"
                v-bind="attrs"
                v-on="on"
                class="text-capitalize mt-1"
                outlined
              >
                {{ itemsPerPage }}<v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in paginationCount"
                :key="index"
                @click="itemsPerPage = item.title"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <Add
        :show="addDialog"
        @close="addDialog = false"
        @confirm="
          (showMessage.title = $t('success')),
            (addDialog = false),
            (showMessage.text = $t('addusersuccess')),
            (showMessage.show = true),
            getUserData()
        "
      />

      <Edit
        :show="editDialog"
        :oldLock="oldLock"
        @close="(editDialog = false), getUserData()"
        @confirm="
          (editDialog = false),
            (showMessage.text = $t('editusersuccess')),
            (showMessage.show = true),
            (showMessage.title = $t('success')),
            $store.commit('clearRemoveUserEditData')
        "
      />
      <AddImport
        :show="addImportDialog"
        :OldUsernameList="OldUsernameList"
        :OldEmpidList="OldEmpidList"
        @close="addImportDialog = false"
        @confirm="(addImportDialog = false), getUserData()"
      />

      <SuccessDialog
        :show="showMessage.show"
        :title="showMessage.title"
        :text="showMessage.text"
        @close="(showMessage.show = false), getUserData()"
      />

      <DeleteConfirmDialog
        :show="confirmMessage.show"
        :text="message"
        @close="(confirmMessage.show = false), getUserData()"
        @confirm="deleteConfirm()"
      />
      <PermissionDialog
        :show="permissionDialog"
        title="Error"
        :text="permissionMessage"
        @close="permissionDialog = false"
      />
    </div>
  </v-container>
</template>

<script>
import Add from "./Add.vue";
import Edit from "./Edit.vue";
import AddImport from "./AddImport.vue";
import { mapGetters } from "vuex";
import exceljs from "exceljs";
import { saveAs } from "file-saver";
export default {
  components: {
    Add,
    Edit,
    AddImport,
  },
  props: {
    search: String,
  },
  data: () => ({
    permissionDialog: false,
    permissionMessage: "",
    addDialog: false,
    addImportDialog: false,
    editDialog: false,
    dialog: false,
    items: [],
    //search: null,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    oldLock: false,
    OldUsernameList: [],
    OldEmpidList: [],
    PricingData: [],
    paginationCount: [
      { title: 5 },
      { title: 10 },
      { title: 20 },
      { title: 30 },
      { title: 40 },
    ],
    loading: true,
    showMessage: {
      show: false,
      title: "Success",
      text: "Updating User is successful.",
    },
    confirmMessage: {
      show: false,
    },
  }),
  computed: {
    ...mapGetters(["userEditData"]),
    headers() {
      return [
        {
          text: "#",
          value: "no",
          class: "tableColor",
          align: "left",
          width: "60px",
        },
        {
          text: this.$t("userid"),
          value: "empID",
          class: "tableColor",
          align: "left",
          width: "156px",
        },
        {
          text: this.$t("fullnamesm"),
          align: "left",
          value: "fullName",
          class: "tableColor",
          width: "185px",
        },
        {
          text: this.$t("email"),
          align: "left",
          value: "email",
          class: "tableColor",
          width: "200px",
        },
        {
          text: this.$t("ph"),
          align: "left",
          value: "mobile",
          class: "tableColor",
          width: "150px",
        },
        {
          text: this.$t("group"),
          align: "left",
          value: "department",
          class: "tableColor",
          width: "250px",
        },
        {
          text: this.$t("department"),
          value: "departmentName",
          align: "left",
          class: "tableColor",
          width: "250px",
        },
        {
          text: this.$t("role"),
          align: "left",
          value: "roleName",
          // value: "levelObject.levelName",
          class: "tableColor",
          width: "152px",
        },
        {
          text: this.$t("status"),
          align: "left",
          value: "checkstatus",
          class: "tableColor",
          width: "144px",
        },
        {
          text: this.$t("Manage"),
          align: "center",
          value: "actions",
          class: "tableColor",
          width: "180px",
          sortable: false,
        },
      ];
    },
    message: {
      get() {
        return (
          this.$t("deletemessage") +
          ' "user ID ' +
          this.userEditData.empID +
          ' ?"'
        );
      },
    },
    filteredData() {
      if (this.search) {
        const searchRegex = new RegExp(this.search, "i");
        return this.items.filter((item) => {
          for (const key in item) {
            if (searchRegex.test(item[key])) {
              return true;
            }
          }
          return false;
        });
      } else {
        return this.items;
      }
    },
  },
  mounted() {
    this.getUserData();
    this.getEmpIdAndUsername();
    this.getPricingData();
  },
  methods: {
    async getCountForEmployee() {
      const res = await this.$axios.get(
        `${this.web_url}Company/GetCompanyActiveUserMP3MP4Count?id=` +
          localStorage.getItem("companyID")
      );
      this.ActiveEmployeeCount = res.data.data[0].activeEmployee;
    },
    async getPricingData() {
      const res = await this.$axios.get(
        `${this.web_urlV4}Package/GetPackageOfCompanyCustomer?CompanyId=` +
          localStorage.getItem("companyID")
      );
      this.PricingData = res.data.data;
    },
    async getEmpIdAndUsername() {
      const res = await this.$axios.get(
        `${this.web_url}Company/GetEmpIDAndUsername?id=` +
          localStorage.getItem("companyID")
      );
      this.OldUsernameList = res.data.data;
      this.OldEmpidList = res.data.data1;
    },
    async checkUserDelete(item) {
      let self = this;
      let checkPermission = self.check_Permissions("usermanagement");
      if (checkPermission) {
        self.confirmMessage.show = true;
        self.$store.commit("saveEditUserData", item);
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to delete User Data.";
      }
    },
    async checkUserEdit(item) {
      console.log("item",item);
      let self = this;
      let checkPermission = self.check_Permissions("usermanagement");
      if (checkPermission) {
        self.editDialog = true;
        self.oldLock = item.lock;
        self.$store.commit("saveEditUserData", item);
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to Edit User Data.";
      }
    },
    async checkUserAdd() {
      let self = this;
      let checkPermission = self.check_Permissions("usermanagement");
      if (checkPermission) {
        if (self.ActiveEmployeeCount < self.PricingData.activeEmpId) {
          self.addDialog = true;
        } else {
          alert(
            "The active employees are reach limit amount. Contact Seedkm administrator to enhance usage"
          );
        }
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to add New User.";
      }
    },
    async exportExcel() {
      let self = this;
      let checkPermission = self.check_Permissions("usermanagement");
      if (checkPermission) {
        let headers = [];
        let keys = [];
        let name = "";

        name = "User Management Report";
        headers = [
          "User ID",
          "Full Name",
          "Email",
          "Phone",
          "Group",
          "Department",
          "Role",
          "Status",
        ];
        keys = [
          "empID",
          "fullName",
          "email",
          "mobile",
          "departmentName",
          "roleName",
          "checkstatus",
        ];
        const fileName = `${"UserManagement"}.xlsx`;
        const filterItems = this.filteredData.map((x) => ({
          empID: x.empID,
          fullName: x.fullName,
          departmentName: x.departmentName,
          email: x.email,
          mobile: x.mobile,
          department: x.department,
          roleName: x.roleName,
          checkstatus: x.lock ? "Active" : "Inactive",
        }));
        const data = [];
        data.push(headers);
        for (const item of filterItems) {
          let row = [];

          for (const key of keys) {
            row.push(item[key] || "");
          }
          data.push(row);
        }
        let workbook2 = new exceljs.Workbook();

        workbook2.addWorksheet(name, {
          views: [{ showGridLines: true }],
        });
        let worksheet2 = workbook2.getWorksheet(1);

        data.forEach((element) => {
          worksheet2.addRow(element);
        });
        worksheet2.getRow(1).eachCell((cell, number) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff9900" },
            bgColor: { argb: "ff9900" },
          };
          cell.font = {
            name: "Calibri",
            size: 11,
            color: { argb: "000000" },
            bold: true,
          };
          console.log(number);
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
        worksheet2.getRows(1, worksheet2.actualRowCount - 1).forEach((x) =>
          x.eachCell((cell) => {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            cell.alignment = { vertical: "middle", horizontal: "center" };
          })
        );
        worksheet2.lastRow.eachCell(function (cell) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
        worksheet2.columns.forEach((column, i) => {
          column.width = headers[i].length + (i <= 12 ? 15 : 19);
        });
        workbook2.xlsx.writeBuffer().then((val) => {
          let blob = new Blob([val], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          });
          saveAs(blob, fileName);
        });
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to export User.";
      }
    },
    async checkUserAddImport() {
      let self = this;
      let checkPermission = self.check_Permissions("usermanagement");
      if (checkPermission) {
        if (self.ActiveEmployeeCount < self.PricingData.activeEmpId) {
          self.getEmpIdAndUsername();
          self.addImportDialog = true;
        } else {
          alert(
            "The active employees are reach limit amount. Contact Seedkm administrator to enhance usage"
          );
        }
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to add New User.";
      }
    },
    async getUserData() {
      let self = this;
      self.loading = true;
      let checkPermission = true;
      if (checkPermission) {
        const res = await self.$axios.get(
          `${self.web_url}EmployeeEXT/GetEmployeeExtAll?id=` +
            localStorage.getItem("companyID")
        );
        console.log("itememail",res.data.data);
        console.log("itememail",res.data.data.emailLanguage);
        self.items = res.data.data.map((v, i) => ({
          ...v,
          no: i + 1,
          checkstatus: v.lock ? "Active" : "Inactive",
        }));
        this.$store.commit("saveUserList", self.items); //save user list for group add in vuex...
        let itemCount = self.items ? self.items.length : 0;
        this.ActiveEmployeeCount = itemCount;
        this.$emit("itemsCount", itemCount);
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to see User Data.";
      }
      self.loading = false;
    },

    async deleteConfirm() {
      const res = await this.$axios.post(
        `${this.web_url}EmployeeEXT/DeleteEmployeeExt?id=` +
          this.userEditData.userID
      );
      if (res) {
        this.confirmMessage.show = false;
        this.showMessage.show = true;
        this.showMessage.text = this.$t("deleteuser");
      }
    },
  },
};
</script>
<style scoped>
::v-deep .v-label {
  font-size: 20px !important;
}
::v-deep .faq-table tr:nth-child(even) {
  background: #e0e0e0;
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: center;
  word-break: break-all;
}
::v-deep .v-dialog {
  border-radius: 35px !important;
}
::v-deep .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  opacity: 1;
}
</style>

<style>
.v-text-field__details {
  display: none !important;
}
.user-table thead th:first-child {
  border-radius: 8px 0 0 8px !important;
}
.user-table thead th:last-child {
  border-radius: 0 8px 8px 0 !important;
}
</style>
